.promo-text-block {
  text-align: center;
  width: 100%;
  max-width: 850px;
  padding: 0 25px;
  margin: 0 auto;
}

.promo-text-block.swim-team {
  text-align: left;
}

@media (max-width: $small-desktop-cutoff-width) {
  .promo-text-block {
    padding: 0px 40px;
  }
  .promo-text-block.about {
    width: 87%;
    padding: 0;
  }
  .promo-text-block p {
    text-align: left;
  }
}

@media (max-width: $phone-cutoff-width) {
  .promo-text-block {
    width: 100%;
  }
  .promo-text-block.swim-team {
    padding: 0px;
  }
}
