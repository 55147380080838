#navbar {
  font-size: 19px;
  height: $navbar-height;
  width: 100%;
  padding: 15px 40px;
  background-color: $primary-color;
  color: white !important;
  &.transparent {
    position: absolute;
    color: white;
    background-color: rgba(0, 0, 0, 0.693);
    #navbar-links {
      color: white;
    }
  }
  > div {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

#company-name {
  display: inline-block;
  a {
    font-size: 28px;
    text-transform: uppercase;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    text-decoration: none;
  }
}

#navbar-links {
  display: inline-block;
  position: relative;
  height: fit-content;
  width: fit-content;
  font-size: 1.1rem;
  float: right;
  vertical-align: middle;
  margin-top: 2px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.657);
  .active {
    color: white; // TODO BLACK COLOR
  }
}

#navbar-link-list {
  list-style: none;
  margin: 0;
}

#navbar-link-list li {
  position: relative;
  display: block;
  float: left;
  margin-left: 21px;
}

#navbar-link-list li:first-child {
  margin-left: 0;
}

#navbar-link-list a {
  position: relative;
  display: block;
  padding: 0;
  cursor: pointer;
}

// Hamburger Menu

#hamburger {
  display: none;
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    width: 26px;
    height: 2px;
    position: absolute;
    background: white;
    border-radius: 4px;
    top: 9px;
    right: 0;
    transition: transform 0.3s ease-out;
  }
  &:after {
    content: "";
    width: 26px;
    height: 2px;
    position: absolute;
    background: white;
    border-radius: 4px;
    top: 20px;
    right: 0;
    transition: transform 0.3s ease-out;
  }
  &.active {
    &:before {
      transform: translateY(3px) rotate(135deg);
      right: 0;
      top: 11px;
    }
    &:after {
      transform: translateY(-3px) rotate(45deg);
      right: 0;
      top: 17px;
    }
  }
}

#hamburger-menu {
  font-family: "Avenir Next", "Avenir", sans-serif;
  font-size: 1.7rem;
  display: none;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 300px;
  padding: 50px;
  background: darken($primary-color, 8%);
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition-duration: 0.5s;
  z-index: 100;
  &.open {
    transform: none;
  }
}

#hamburger-menu > a {
  display: block;
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.657);
  &.current {
    color: white;
    font-weight: bold;
  }
}

// Phone

@media (max-width: $phone-cutoff-width) {
  #navbar {
    background-color: $primary-color !important;
    padding-left: 25px;
    padding-right: 25px;
  }

  #navbar-links {
    display: none;
  }

  #hamburger {
    display: block;
  }

  #hamburger-menu {
    display: block;
  }
}
