// Variables
$navbar-height: 63px;
$footer-height: 94px;
$mobile-footer-height: 205px;
$extra-small-mobile-footer-height: 203px;
$small-desktop-cutoff-width: 1000px;
$phone-cutoff-width: 635px;
$max-site-width: 1600px;

// Colors
$heading-text-color: rgb(10, 10, 10);
$subheading-text-color: rgb(14, 14, 14);
$paragraph-text-color: rgb(30, 30, 30);
$primary-color: #004285;
$page-background-color: white;
$footer-color: darken($primary-color, 15%);
$facebook-blue: rgb(60, 89, 155);

@import "fonts";
@import "general";
@import "footer";
@import "navbar";

@import "Promo/imagefeed";
@import "Promo/imagespreader";
@import "Promo/informationblock";
@import "Promo/textblock";
@import "Promo/videoplayer";
