.promo-image-spreader {
  display: flex;
  width: 87%;
  margin: 0 auto;
  > div {
    position: relative;
    min-width: 200px;
    max-width: 400px;
  }
  .image-container {
    display: block;
    height: 0;
  }
  img,
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
}

.about-image-spreader {
  justify-content: space-between;
  > div {
    width: 25%;
  }
  .image-container {
    padding-top: 100%;
  }
  .overlay {
    opacity: 0;
    color: white;
    font-size: 1.3rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.315);
      transition-duration: 0.2s;
    }
  }
}

// TODO RENAME TO SOMETHING MORE GENERAL?
.pool-image-spreader {
  justify-content: space-around;
  > div {
    width: 35%;
    min-width: 300px;
  }
  .image-container {
    padding-top: 60%;
  }
}

// General Small Screen Specifics

$block-image-spacing: 35px;

@mixin promo-stacker-block {
  flex-direction: column;
  > div {
    width: 100%;
    max-width: unset;
  }
}

// About Image Spreader Small Screen Specifics

@media (max-width: 750px) {
  .about-image-spreader {
    @include promo-stacker-block;
    > div {
      position: relative;
      height: 350px;
    }
    div + div {
      margin-top: $block-image-spacing;
    }
    .overlay {
      opacity: 1 !important;
      background-color: rgba(0, 0, 0, 0.377) !important;
    }
  }
}

@media (max-width: 550px) {
  .about-image-spreader {
    width: 100%;
    div + div {
      margin-top: 0;
    }
  }
}

// General Image Spreader Small Screen Specifics

@media (max-width: $small-desktop-cutoff-width) {
  .pool-image-spreader {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .pool-image-spreader {
    width: 90%;
    @include promo-stacker-block;
    > div {
      position: relative;
      height: 350px;
    }
    div + div {
      margin-top: $block-image-spacing;
    }
  }
}

@media (max-width: $phone-cutoff-width) {
  .pool-image-spreader {
    width: 100%;
  }
}

// TODO TURN 750 TO VARIABLE
// TODO CHANGE ALL "POOL SPREADER" NAMES TO "GENERAL"
