$footer-text-color: rgba(255, 255, 255, 0.625);
$footer-hover-color: rgba(255, 255, 255, 0.95);
$mobile-vertical-spacing: 13px;

.footer {
  font-size: 0.9em;
  color: $footer-text-color;
  background-color: $footer-color;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  .content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 29px 60px;
    max-width: #{$max-site-width + 80px};
    > div {
      line-height: 18px;
    }
    .contact-section {
      overflow: visible;
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    a {
      display: block;
      transition: color 0.1s ease-in-out;
      &:hover {
        color: $footer-hover-color;
      }
    }
  }
  .facebook-logo {
    height: fit-content;
    margin-left: 16px;
    > svg {
      display: block;
      height: 33px;
      fill: $footer-text-color;
      transition: fill 0.1s ease-in-out;
      cursor: pointer;
      &:hover {
        fill: $footer-hover-color;
      }
    }
  }
}

@media (max-width: $phone-cutoff-width) {
  .footer {
    font-size: 1em;
    .content {
      padding: 0;
      width: fit-content;
      margin: 30px auto;
      flex-direction: column;
      text-align: center !important;
      .address-section {
        margin-bottom: $mobile-vertical-spacing;
      }
      .contact-section {
        display: block;
        margin: 0;
        height: fit-content;
        text-align: center !important;
        a + a {
          margin-top: $mobile-vertical-spacing;
        }
      }
    }
    .facebook-logo {
      margin: #{$mobile-vertical-spacing + 1px} auto 0 auto;
      width: fit-content;
      > svg {
        height: 33px;
      }
    }
  }
}

@media (max-width: #{$phone-cutoff-width - 250px}) {
  .footer {
    font-size: 0.9em;
    .facebook-logo {
      margin: #{$mobile-vertical-spacing + 3px} auto 0 auto;
      width: fit-content;
      > svg {
        height: 29px;
      }
    }
  }
}
