*,
body {
  margin: 0;
  padding: 0;
}
*,
:after,
:before {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
}

html {
  height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  height: 100%;
  background: white;
  color: rgb(10, 10, 10);
  font-size: 16px;
  padding-top: 0px;
  margin-top: 0px;
  -webkit-tap-highlight-color: transparent;
}

#app {
  min-height: 100%;
  background-color: $page-background-color;
  position: relative;
}

a,
div {
  overflow-x: hidden;
  overflow-y: hidden;
}

a {
  text-decoration: unset;
  color: unset;
  cursor: pointer;
}

input,
button {
  outline: none !important;
}

// Font Standards

// TODO H1 and H2? Especially for other browsers? Copy chrome defaults so safe

h1 {
  color: $heading-text-color;
}

p {
  color: $paragraph-text-color;
}

h3 {
  color: $subheading-text-color;
  font-size: 1.17rem;
  margin-top: 0;
  margin-bottom: 3px;
}

@media (max-width: $phone-cutoff-width) {
  h1 {
    font-size: 1.75em;
  }
}

// Page Container

.page-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 80px 0 #{80px + $footer-height} 0;
  > div + div {
    margin-top: 70px;
  }
}

@media (max-width: $small-desktop-cutoff-width) {
  .page-container {
    width: 95%;
    padding-left: 40px;
    padding-right: 40px;
  }
  .page-container.about {
    width: 100%;
  }
}

@media (max-width: $phone-cutoff-width) {
  .page-container {
    width: 100%;
    margin: 0 auto;
    padding: 40px 25px #{40px + $mobile-footer-height} 25px;
    > div + div {
      margin-top: 40px;
    }
  }
}

@media (max-width: #{$phone-cutoff-width - 250px}) {
  .page-container {
    padding: 40px 25px #{40px + $extra-small-mobile-footer-height} 25px;
  }
}

// About Page Specifics

.page-container.about {
  margin: 0 auto;
  padding: 55px 0 #{55px + $footer-height} 0;
  background-color: white;
}

.about-tagline {
  font-size: 1.2rem;
  color: #615c56;
  margin-bottom: 17px;
}

.header-image {
  width: 100%;
  vertical-align: middle;
}

@media (max-width: $phone-cutoff-width) {
  .page-container.about {
    margin: 0 auto;
    padding: 45px 0 $mobile-footer-height 0;
  }

  .header-image {
    margin-top: $navbar-height;
  }
}

@media (max-width: #{$phone-cutoff-width - 250px}) {
  .page-container.about {
    padding: 45px 0 $extra-small-mobile-footer-height 0;
  }
}
