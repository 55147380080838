.promo-information-block {
  padding: 0px 40px;
  width: 87%;
  margin-left: auto;
  margin-right: auto;
  > div {
    padding: 0px 20px;
  }
  > div:first-of-type {
    text-align: left;
    padding-left: 35px; // UNDO ON OTHER SNAP POINTS
  }
  .page-title {
    color: darken($primary-color, 8%);
    font-size: 1.87em;
    // font-size: 1.3em; OLD
  }
  .sub-section {
    font-weight: 100;
  }
  .sub-block {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }
}

.promo-information-block .feature-list-header {
  padding-top: 8px;
  padding-bottom: 4px;
}

.promo-information-block .feature-list {
  padding-left: 17px;
}

.promo-information-block .price-list {
  margin-top: 20px;
  > div {
    margin-bottom: 10px;
  }
}

@media (max-width: $small-desktop-cutoff-width) {
  .promo-information-block {
    .page-title {
      font-size: 1.65em;
    }
    > div:first-of-type {
      width: 40%;
    }
    > div:last-of-type {
      width: 60%;
    }
    width: 100%;
    padding: 0;
  }
}

@media (max-width: $phone-cutoff-width) {
  .promo-information-block {
    > div:first-of-type {
      text-align: center;
      padding-left: 0;
    }
    > div {
      padding: 0px 0px;
    }
  }
  .sub-block {
    width: 100% !important;
    h1 {
      margin-bottom: 5px;
    }
  }
  .promo-information-block .no-collapse {
    width: 50% !important;
  }
}
