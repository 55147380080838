.promo-feed-title {
  display: block;
  width: fit-content;
  padding: 0px 20px;
  font-size: 1.2rem;
  margin-left: 20px;
  margin-bottom: 8px;
  color: gray;
  .facebook-logo {
    height: 23px;
    margin-right: 8px;
    fill: gray;
    vertical-align: middle; // TODO BOTTOM?
  }
  div {
    display: inline-block;
    margin-top: 3px;
    vertical-align: middle;
  }
  &:hover .facebook-logo {
    fill: darken($facebook-blue, 5%);
  }
}

.promo-feed-images {
  > div {
    display: inline-block;
    width: calc(100% / 6);
    vertical-align: middle;
    background-position: center center;
    background-size: cover;
  }
  > div > div {
    height: 260px;
  }
}

// Tablet (3x2)
@media (max-width: 1100px) {
  .promo-feed-images {
    > div {
      width: calc(100% / 3);
    }
    > div:nth-child(n + 7) {
      display: none;
    }
  }
}

// Phone (2x3)
@media (max-width: 750px) {
  .promo-feed-title {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
  }

  .promo-feed-images {
    img {
      width: calc(100% / 2);
    }
    img:nth-child(n + 7) {
      display: none;
    }
  }
}
