.promo-video-player {
  padding: 0 !important;
  > div {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//TODO ???$phone-cutoff-width
@media (max-width: 750px) {
  .promo-video-player {
    margin-top: 5px;
  }
}
